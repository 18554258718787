import { render } from "preact"
import { useCallback, useState } from "preact/hooks"

import { fetchJSON } from "./fetch.js"
import { qs } from "./utils.js"

const Happy = () => (
  <svg
    width="110"
    height="110"
    viewBox="-5 -5 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon"
  >
    <path
      d="M68 31C64.3186 31 60.4976 33.9954 58.1838 36.2002C57.4758 36.8749 58.126 37.9546 59.0803 37.7406C61.6024 37.1749 65.1985 36.5 68 36.5C70.7444 36.5 73.9274 37.1477 76.1612 37.7059C77.0885 37.9376 77.7835 36.9226 77.1384 36.2172C75.1206 34.0108 71.6909 31 68 31Z"
      fill="currentColor"
    />
    <path
      d="M32 31C28.3186 31 24.4976 33.9954 22.1838 36.2002C21.4758 36.8749 22.126 37.9546 23.0803 37.7406C25.6024 37.1749 29.1985 36.5 32 36.5C34.7444 36.5 37.9274 37.1477 40.1612 37.7059C41.0885 37.9376 41.7835 36.9226 41.1384 36.2172C39.1206 34.0108 35.6909 31 32 31Z"
      fill="currentColor"
    />
    <path
      d="M73.2023 64H26.7977C26.0136 64 25.5576 64.8381 26.0303 65.4635C28.8132 69.1453 37.1486 78.5 50 78.5C62.8514 78.5 71.1868 69.1453 73.9697 65.4635C74.4425 64.8381 73.9864 64 73.2023 64Z"
      fill="currentColor"
    />
    <circle cx="50" cy="50" r="48.5" stroke="currentColor" stroke-width="5" />
  </svg>
)

const Neutral = () => (
  <svg
    width="110"
    height="110"
    viewBox="-5 -5 105 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon"
  >
    <rect x="25" y="64" width="50" height="3" fill="currentColor" />
    <circle cx="50" cy="50" r="48.5" stroke="currentColor" stroke-width="5" />
    <ellipse cx="34" cy="35" rx="5" ry="6" fill="currentColor" />
    <ellipse cx="66" cy="35" rx="5" ry="6" fill="currentColor" />
  </svg>
)

const Triangle = () => (
  <svg
    width="52"
    height="86"
    viewBox="0 0 52 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon"
  >
    <path
      d="M52 43L1.66553e-06 86L5.42471e-06 -2.27299e-06L52 43Z"
      fill="currentColor"
    />
  </svg>
)

const Comic = ({ cssClass, comic, animation, handleRating }) => {
  const [rating, setRating] = useState(4)

  return (
    <div class={`comic ${cssClass}`}>
      <figure class="comic__image">
        <img src={comic.image} class={animation} />
      </figure>
      <div class="comic__rating">
        <div class="comic__rating-emoji">
          <Neutral />
        </div>
        <input
          type="range"
          min="1"
          max="7"
          step="1"
          value={rating}
          onInput={(e) => {
            setRating(e.target.value)
          }}
          list="markers"
        />
        <div class="comic__rating-emoji">
          <Happy />
        </div>
      </div>
      <button
        class="button button--continue"
        onClick={() => handleRating(+rating)}
      >
        Weiter
      </button>
    </div>
  )
}

const calculateSums = (comics, ratings) => {
  const sums = {}
  for (let comic of comics) {
    sums[comic.type] = (sums[comic.type] || 0) + ratings.get(comic.id)
  }
  return sums
}

const calculateOrderedTypes = (types, comics, ratings) => {
  const sums = calculateSums(comics, ratings)
  const r = types.map((type) => ({ ...type, score: sums[type.type] || 0 }))
  r.sort((a, b) => b.score - a.score)
  return r
}

const Evaluation = ({ comics, types, ratings, stats, doReset }) => {
  const orderedTypes = calculateOrderedTypes(types, comics, ratings)

  return (
    <div class="evaluation flow">
      <h1>Dein Humortyp</h1>
      <div
        class="evaluation__description flow"
        dangerouslySetInnerHTML={{ __html: orderedTypes[0].description }}
      />
      <h2>So lustig fandest du die anderen Humortypen</h2>
      <p>
        Du hast nicht nur einen Humortyp. Die folgenden Balkendiagramme zeigen
        dir, wie lustig du die Comics und Memes des entsprechenden Typs fandest.
      </p>
      <Self orderedTypes={orderedTypes} />
      <h2>Die anderen AUSSTELLUNGSBESUCHERINNEN</h2>
      <p>
        Hier siehst du, zu welchem Humortyp die bisherigen
        AusstellungsbesucherInnen gehören.
      </p>
      {stats ? (
        <Pie orderedTypes={orderedTypes} visitors={stats.visitors} />
      ) : null}
      <h2>Andere Humortypen</h2>
      <p>
        Interessieren dich die anderen Humortypen? Hier findest du ihre
        Beschreibung.
      </p>
      {orderedTypes.slice(1).map((type) => (
        <details class="evaluation__other details" key={type.type}>
          <summary>
            <Triangle />
            <h3>{type.title}</h3>
          </summary>
          <div
            class="evaluation__description flow"
            dangerouslySetInnerHTML={{ __html: type.description }}
          />
        </details>
      ))}
      <button class="button button--end" onClick={doReset}>
        Zum Start
      </button>
    </div>
  )
}

const Round = ({ comics, types, doReset }) => {
  const [current, setCurrent] = useState(-2)
  const [ratings] = useState(() => new Map())
  const [stats, setStats] = useState()

  const now = comics[current],
    next = comics[current + 1]

  const advance = () => setCurrent((c) => c + 1)

  return (
    <div class="station">
      {current < -1 ? (
        <div class="station station--intro">
          <h1 class="station__intro">Humor Check</h1>
          <button class="button button--continue" onClick={advance}>
            Weiter
          </button>
        </div>
      ) : current < 0 ? (
        <div class="station station--explanation">
          <div class="flow">
            <h1>Erklärung</h1>
            <ol>
              <li>Schau dir die folgenden Comics und Memes an.</li>
              <li>Bewerte mithilfe des Reglers, wie lustig du sie findest.</li>
              <li>Erfahre am Schluss deinen Humortyp.</li>
            </ol>
          </div>
          <button class="button button--continue" onClick={advance}>
            Weiter
          </button>
        </div>
      ) : current < comics.length ? (
        <>
          {next ? (
            <Comic cssClass="comic--hidden" comic={next} key={next.id} />
          ) : null}
          <Comic
            comic={now}
            animation={"animation"}
            handleRating={(rating) => {
              ratings.set(now.id, rating)
              advance()

              if (current + 1 === comics.length) {
                const orderedTypes = calculateOrderedTypes(
                  types,
                  comics,
                  ratings,
                )
                fetchJSON(".", {
                  method: "POST",
                  body: JSON.stringify({
                    ratings: Object.fromEntries(ratings.entries()),
                    sums: Object.fromEntries(
                      orderedTypes.map((type) => [type.type, type.score]),
                    ),
                    type: orderedTypes[0].type,
                  }),
                  header: {
                    "content-type": "application/json",
                  },
                }).then((data) => {
                  setStats(data.stats)
                })
              }
            }}
            key={now.id}
          />
        </>
      ) : (
        <Evaluation
          comics={comics}
          types={types}
          ratings={ratings}
          stats={stats}
          doReset={doReset}
        />
      )}
      {current > -2 ? (
        <button class="button button--reset" onClick={doReset}>
          Zum Start
        </button>
      ) : null}
    </div>
  )
}

const Station = (props) => {
  const doReset = useCallback(() => {
    window.location.reload()
  }, [])
  return <Round {...props} doReset={doReset} />
}

const Pie = ({ orderedTypes, visitors }) => {
  const parts = orderedTypes.map((type) => visitors[type.type] || 0)
  const total = parts.reduce((a, b) => a + b)
  const tau = Math.PI * 2
  const pieces = parts.reduce((arr, curr) => {
    let prev = arr.length ? arr.at(-1)[1] : 0
    let from = (prev / total) * tau
    let to = ((prev + curr) / total) * tau
    return [...arr, [prev, prev + curr, from, to, to - from > Math.PI ? 1 : 0]]
  }, [])

  const r = 200
  const rr = `${r},${r}`
  const at = (angle) => {
    const x = Math.round(Math.sin(angle) * r)
    const y = Math.round(-Math.cos(angle) * r)
    return `${x + r},${y + r}`
  }

  const patterns = ["xLines", "yLines", "dots"]
  return (
    <svg viewBox="-10 -10 820 420" fill="currentColor">
      <defs>
        <pattern
          id="xLines"
          x="0"
          y="0"
          width="8"
          height="8"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="8" height="3" />
        </pattern>
        <pattern
          id="yLines"
          x="0"
          y="0"
          width="8"
          height="8"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="3" height="8" />
        </pattern>
        <pattern
          id="dots"
          x="0"
          y="0"
          width="8"
          height="8"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="3" height="3" />
        </pattern>
      </defs>
      {pieces.map((p, i) => (
        <path
          d={`M${rr} L${at(p[2])} A${rr} 0 ${p[4]} 1 ${at(p[3])} Z`}
          stroke="currentColor"
          stroke-linejoin="bevel"
          stroke-width="2"
          fill={`url(#${patterns[i]})`}
          key={i}
        />
      ))}
      <g transform="translate(500 75)">
        {orderedTypes.map((type, i) => (
          <g transform={`translate(0 ${i * 100})`} key={type.type}>
            <rect
              x="0"
              y="0"
              height="44"
              width="44"
              stroke="currentColor"
              stroke-width="2"
              fill={`url(#${patterns[i]})`}
            />
            <text x="50" y="30">
              {type.title}
            </text>
          </g>
        ))}
      </g>
    </svg>
  )
}

const Self = ({ orderedTypes }) => {
  // console.debug({ types, self })
  const w = 800
  const min = 6.5,
    max = 49.5

  return (
    <svg viewBox="-10 -10 820 420" fill="currentColor">
      {orderedTypes.map((type, i) => {
        const v = type.score
        const x = (w * (v - min)) / (max - min)
        return (
          <g transform={`translate(0 ${i * 140})`} key={type.type}>
            <text x="0" y="10">
              {type.title}
            </text>
            <g transform="translate(0 24)">
              <g transform="translate(0 50) scale(0.35)">
                <Neutral />
              </g>
              <g transform={`translate(${w - 38} 50) scale(0.35)`}>
                <Happy />
              </g>
              <rect x={x} y="0" width="2" height="40" fill="red" />
              <rect
                x="0"
                y="0"
                width={w}
                height="40"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
              />
              {v < max - 4 ? (
                <text x={x + 8} y={28}>
                  Du
                </text>
              ) : (
                <text x={x - 8} y={28} text-anchor="end">
                  Du
                </text>
              )}
            </g>
          </g>
        )
      })}
    </svg>
  )
}

export function renderStation(el) {
  const props = JSON.parse(qs("script", el).textContent)

  render(<Station {...props} />, el)
}

export function renderStats(el) {
  const props = JSON.parse(qs("script", el).textContent)
  const { stats, types } = props
  const self = { INCONGRUENT: 10, NONSENSE: 49, SEXUAL: 16 }
  const orderedTypes = types.map((type) => ({
    ...type,
    score: self[type.type] || 0,
  }))
  render(
    <div class="evaluation">
      <div class="stats">
        <Self orderedTypes={orderedTypes} />
        <Pie orderedTypes={orderedTypes} visitors={stats.visitors} />
      </div>
    </div>,
    el,
  )
}
